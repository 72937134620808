<template>
  <div class="text-center">
    <h3 class="text-center">
      {{ $t('colleagueSurvey.surveyCompleted.UmfrageVollstaendig_0') }}
    </h3>
    <p>
      {{ $t('colleagueSurvey.surveyCompleted.UmfrageVollstaendig_1') }}
    </p>
  </div>
</template>

<script>
export default {
  name: "SurveyCompleted",
};
</script>