<template>
  <div class="text-center">
    <v-progress-circular indeterminate />
  </div>
</template>

<script>
export default {
  name: "LoadingAnimation",
};
</script>